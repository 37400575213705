import React from 'react';
import {useNavigate} from "react-router-dom";

const ComingSoon = () => {
    const navigate = useNavigate();

    const handleClick = (path) => {
        navigate(path);
    };
    return (
        <div>
            <header>
                <button className={"glowing-btn"} onClick={() => handleClick('/')}>
                    <span className='glowing-txt'>Back <span className='faulty-letter'>To </span>Home</span>
                </button>
            </header>
            <section className={"bannerForComingSoon"}>
                <div >

                </div>
            </section>
        </div>
    );
}

export default ComingSoon;
