import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Blog from './components/Blog';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';

const App = () => {
    return (
        <Router>
            <Routes>
              <Route exact path='/' element={<Home />} />
              <Route path='/blog' element={<Blog/>} />
              <Route path='/about' element={<About/>} />
              <Route path='/services' element={<Services />} />
              <Route path='/contact' element={<Contact />} />
            </Routes>
        </Router>
    );
}

export default App;
