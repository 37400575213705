import React from 'react';
import { useNavigate } from 'react-router-dom';
import ComingSoon from './ComingSoon';

function About() {
    const navigate = useNavigate();

    const handleClick = (path) => {
        navigate(path);
    };
    return (
        <ComingSoon></ComingSoon>
    );
}

export default About;
