import { Link, useNavigate } from 'react-router-dom';
import React from 'react';
import "./style.css"

const Home = () => {
    const navigate = useNavigate();

    const handleClick = (path) => {
        navigate(path);
    };

    return (
    <body>
        <header id="header">
            <a href="/" className="logo">
                <button className={"glowing-btn"} onClick={() => handleClick('/')}>
                    <span className='glowing-txt' style={{ fontSize: '20px'}}>HYBRID<span className='faulty-letter'>.</span>AM</span>
                </button>
            </a>
            <ul>
                <li><Link to="/about" onClick={() => handleClick('/about')}>Մեր մասին</Link></li>
                <li><Link to="/services" onClick={() => handleClick('/services')}>Ծառայություններ</Link></li>
                <li><Link to="/contact" onClick={() => handleClick('/contact')}>Կոնտակտներ</Link></li>
                <li><Link to="/blog" onClick={() => handleClick('/blog')}>Բլոգ</Link></li>
            </ul>
        </header>
        <section className="banner" id="home">
            <h2>ՀԻԲՐԻԴԱՅԻՆ<br/> <span>ԱՎՏՈՄԵՔԵՆԱՆԵՐԻ </span>ՍՊԱՍԱՐԿՈՒՄ</h2>
        </section>


        <section className="sec about" id="services">
            <div className="content">
                <div className="mxw800p">
                    <h3>ՄԵՐ ԾԱՌԱՅՈՒԹՅՈՒՆՆԵՐԸ</h3>
                    <p>Մենք իրականացնում ենք հիբրիդային ավտոմեքենաների ամբողջական սպասարկում։</p>
                </div>
                <br/>
                <div className="services">
                    <div className="box">
                        <div className="iconBx">
                            <img src="../../public/img/icon1.png" alt=""/>
                            <div className="content">
                                <h2>Ավտոմեքենայի դիագնոստիկա</h2>
                                <p>Հիբրիդային մեքենաների խնդիրների վերհանում և լուծումների տրամադրում։</p>
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="iconBx">
                            <img src="../../public/img/icon2.png" alt=""/>
                            <div className="content">
                                <h2>Մարտկոցների դիագնոստիկա</h2>
                                <p>Հիբրիդային մարտկոցների դիագնոստիկա համակարգչային հատուկ ծրագրերի և մասնագիտացված սարքավորումների միջոցով։</p>
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="iconBx">
                            <img src="../../public/img/icon3.png" alt=""/>
                            <div className="content">
                                <h2>Մարտկոցների փոխարինում</h2>
                                <p>Մաշված և փոխարինման ենթական մարտկոցների փոխարինում նորերով՝ երաշխիքի տրամադրմամբ։</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="sec" id="about">
            <div className="content">
                <div className="mxw800p">
                    <h3>ԻՆՉՈՒ՞ ԴԻՄԵԼ ՄԵԶ</h3>
                    <p style={{ marginBottom: '30px'}}>Լինելով հայկական շուկայի առաջատար՝ մենք առաջարկում ենք հիբրիդային ավտոմեքենաների մարտկոցների սպասարկման լավագույն և բացառիկ պայմաններ՝</p>
                    <ul>
                        <li style={{  textAlign: 'left', fontSize: '20px', marginBottom: '10px'}} >հիբրիդային մեքենաների բոլոր մարտկոցների համակարգչային դիագնոստիկա և մարտկոցների աշխատանքային վիճակի մասին ինֆորմացիայի տրամադրում գրաֆիկների տեսքով 1-2 օրում,</li>
                        <li style={{  textAlign: 'left', fontSize: '20px', marginBottom: '10px'}} >խնդրային մարտկոցների բացահայտում և փոխարինում նորով,</li>
                        <li style={{  textAlign: 'left', fontSize: '20px', marginBottom: '10px'}} >մատչելի գներ,</li>
                        <li style={{  textAlign: 'left', fontSize: '20px', marginBottom: '10px'}} >երաշխիքային սպասարկում։</li>
                    </ul>
                </div>
            </div>
        </section>

        <section className="sec" id="blog">
            <div className="content">
                <div className="mxw800p">
                    <h1>Հղումներ բլոգ բաժնից, ինչպես օրինակ այս նկարում է, Pro Blog-ի ներքի հատվածի նկարագրության անհրաժեշտություն չկա։</h1>
                </div>
            </div>
        </section>

        <section className="sec contact" id="contact">
            <div className="content">
                <div className="mxw800p">
                    <h3>ԿԱՊ ՄԵԶ ՀԵՏ</h3>
                    <p>Խորհրդատվության համար զանգահարեք հետևյալ հեռախոսահամարներով՝<br/> <br/>
                        <a href="tel:+37493483735">+374 (93) 48-37-35</a> <br/>
                        <a href="tel:+37495481034">+374 (95) 48-10-34</a>
                    </p>
                    <p>
                        Կամ թողեք Ձեր տվյալները և մենք կապ կհաստատենք Ձեզ հետ։
                    </p>
                </div>
                <div className="contactForm">
                    <form>
                        <div className="row100">
                            <div className="inputBx50">
                                <input type="text" name="" placeholder="ԱԱՀ"/>
                            </div>
                            <div className="inputBx50">
                                <input type="text" name="" placeholder="Էլ․ հասցե"/>
                            </div>
                            <div className="inputBx50">
                                <input type="number" name="" placeholder="Հեռախոսահամար"/>
                            </div>
                        </div>
                        <div className="row100">
                            <div className="inputBx100">
                                <input type="submit" value="Ուղարկել"/>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="sci">
                    <ul>
                        <li><a href="/"><img src="../../public/img/facebook.png" alt={''}/></a></li>
                        <li><a href="/"><img src="../../public/img/twitter.png" alt={''}/></a></li>
                        <li><a href="/"><img src="../../public/img/linkedin.png" alt={''}/></a></li>
                    </ul>
                </div>
            </div>
        </section>
    </body>
  );
}

export default Home;
